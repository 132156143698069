<template>
    <div class="main">
        <div style="display: inline-block; width:100%;text-align:center;">
            <div style="width: 1280px;margin:0 auto;">
                <video muted autoplay loop class="video1"><source src="@/assets/images/web/ai/1.mp4" type="video/mp4">
                    
                </video>
                
            </div>
            
            <audio id="bgMusic"  loop autoplay="autoplay" preload="auto"><source src="@/assets/images/web/ai/1.mp3" type="audio/mpeg"></audio>
        </div>
        <div class="msgbox">
            <button class="play" @click="onplay"></button>
            <div class="answer">{{answer}}</div>
            <div class="ask">
                <input class="input" v-model="content" @keydown.enter="onclick">
                <button class="send" @click="onclick">发送</button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                content : "",
                content_ :"",
                answer:"",
                answer_:"",
                step:0,
                count :0,
                isplay :false,
            };
        },
        mounted() {
            this.countdown();
            // var audio = document.getElementById("bgMusic");
            // audio.play();
        },
        methods: {
            onplay(){
                var audio = document.getElementById("bgMusic");
                if(!this.isplay){
                    audio.play();
                    this.isplay = true;
                }else{
                    audio.pause();
                    this.isplay = false;
                }
            },
            onclick() {
                if (this.step > 0){
                    alert("请稍等！");
                    return;
                }
                if (this.content==""){
                    alert("请输入对话信息！");
                    return;
                }
                var params = {"msg":this.content}
                this.answer ="我：「"+this.content+"」";
                this.content_ = this.content;
                this.content = "";
                this.step = 1;
                this.count = 0;
                return new Promise(resolve => {
                    this.$axios.Chat(params).then(res => {
                        const data = res.data;
                        console.log(data);
                        
                        if (data.code == 0){
                            this.step =2;
                            this.answer_ = data.data.msg.choices[0].text;
                            this.answer_ = this.answer_.replace(/\s*/g,"");
                            // this.answer = "她：「"+this.answer_+"」";
                            this.count = 0;
                            resolve(data.data);
                        }else{
                            this.step = 0;
                            alert("请求生成失败!");
                        }
                        
                    })
                })
            },
            countdown(){
                var id = setInterval(frame, 100);
                var that = this;
                function frame() {
                    // console.log(that.count);
                    if(that.step == 1){
                        // clearInterval(id);
                        var dot = "";
                        for (var i=0;i<(that.count/10)%6;i++)
                        {
                            dot += ".";
                        }
                        that.answer ="我：「"+that.content_+"」\n"+dot;
                        // console.log("click  "+that.count);
                        if(that.count >= 150){
                            that.answer_ = "这个话题太难了，我还在学习中，咱们换个换题聊聊吧!";
                            that.count = 0;
                            that.step = 2;
                        }
                    }else if(that.step == 2){
                        if(that.count <= that.answer_.length){
                            that.answer = "她：「"+that.answer_.slice(0, that.count)+"」";
                        }else{
                            that.step = 0;
                        }
                    }
                    
                    that.count++; 
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
.msgbox{
    position: absolute;
    width: 1280px;
    height: 720px;
    /* background-color: #FF0000; */
    top:60px;
    /* margin:0 auto; */
    left:50%;
    margin-left:-640px;
}
.answer{
    background-color: #000;
    /* position: absolute; */
    /* display: inline-block;  */
    margin:0 auto;
    text-align:center;
    /* width: 100%; */
    width: 1280px;
    height: 80px;
    font-size: 24px;
    opacity: 0.5;
    margin-top:440px;
    color: #fff;
    /* margin-top: 100px; */
    white-space: pre-wrap;
    overflow-y:auto;
}.ask{
    background-color: #000;
    /* display: inline-block;  */
    margin:0 auto;
    text-align:center;
    width: 60%;
    height: 80px;
    font-size: 24px;
    opacity: 0.5;
    top:100px;
    color: #fff;
    margin-top: 10px;
}
.input{
    color: #000;
    margin-top: 10px;
}
.send{
    margin-left: 100px;
    color: #000;
}
.play{
    /* position: absolute; */
    /* display: inline-block;  */
    /* margin:0 auto; */
    /* text-align:center; */
    /* width: 100%; */
    width: 40px;
    height: 40px;
    /* top:-100px; */
    color: #000;
    /* margin-top: 100px; */
    margin-left: 1200px;
    margin-top:40px;
}
</style>